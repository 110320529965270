<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col md="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" enctype="multipart/form-data">
                                            <ValidationProvider name="Circular Name (En)" vid="circular_name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="circular_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('barc_config.circular_name_en')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="circular_name"
                                                    v-model="circularInfo.circular_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Circular Name (Bn)" vid="circular_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="circular_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('barc_config.circular_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="circular_name_bn"
                                                    v-model="circularInfo.circular_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Memo No" vid="memo_no" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="memo_no"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('barc_config.memo_no')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="memo_no"
                                                    v-model="circularInfo.memo_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Circular Date" vid="circular_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="circular_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('barc_config.circular_date')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input class="form-control"
                                                        v-model="circularInfo.circular_date"
                                                        placeholder="Select Date"
                                                        id="datepicker"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Deadline" vid="deadline" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="deadline"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('barc_config.deadline')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input class="form-control"
                                                        v-model="circularInfo.deadline"
                                                        placeholder="Select Date"
                                                        id="datepicker"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Attachment" vid="attachment">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="attachment"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{$t('committee.attachment')}}
                                                </template>
                                                    <b-form-file
                                                    id="attachment"
                                                    v-on:change="onFileChange"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ fileValidationMsz }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { circularInfoStore, circularInfoUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.circularInfoData()
            this.circularInfo = tmp
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            circularInfo: {
                circular_name: '',
                circular_name_bn: '',
                memo_no: '',
                circular_date: '',
                attachment: ''
            },
            attachmentDemo: [],
            fileValidationMsz: ''
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    methods: {
        circularInfoData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        onFileChange (e) {
            this.attachmentDemo = e.target.files[0]
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            var formData = new FormData()
            Object.keys(this.circularInfo).map(key => {
                if (key === 'attachment') {
                formData.append(key, this.attachmentDemo)
                } else {
                formData.append(key, this.circularInfo[key])
                }
            })

            if (this.circularInfo.id) {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${circularInfoUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, circularInfoStore, formData, config)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
                if (result.errors.attachment) {
                    this.fileValidationMsz = 'Maximum file size must be 2 MB'
                }
            }
        }
    }
}
</script>
